import React, { useEffect } from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { Table, TableWithAction, Tabs, Breadcrumbs } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Card, Input, ChatBox } from 'components';
import { useState } from 'react';
import moment from 'moment';
import Store from 'store';
import { VarHelper, TimeHelper } from 'helpers';

const PortfolioDetails: IScreen = () => {
  const { width, height } = useWindowDimensions();
  const { route } = useNavFunc();
  const { tab: initialTab } = route.params || {};
  const [tab, selectTab] = useState('1');

  const [{ listClients, listDocs, listCerts, listAvailableCasks, listPortfolioStrategy, invoices }, cApi] = Store.Client.createStore();
  const [{ email, name }] = Store.User.createStore();
  const [{ }, mApi] = Store.Message.createStore();
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })

  const [{ listPages }, iApi] = Store.Info.createStore();

  useEffect(() => {
    iApi.getListPages();
  }, []);

  const pageData = listPages.find(val => val.Title === 'Portfolio Details');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const listTabs = [
    // { id: '1', title: 'YOUR CASKS' },
    { id: '2', title: 'AVAILABLE CASKS' },
    { id: '4', title: 'DOCUMENTS & CERTIFICATES' }, { id: '5', title: 'PORTFOLIO STRATEGY' },
    { id: '6', title: 'INVOICES' }];

  useEffect(() => {
    const findId = listTabs.find(val => val.title === initialTab);
    if (!findId) return;
    selectTab(findId.id);
  }, [initialTab]);

  useEffect(() => {
    cApi.getListClients();
    cApi.getListDocs();
    cApi.getListCerts();
    cApi.getListPortfolioStrategy();
    cApi.getListAvailableCasks();
    cApi.getInvoices();
  }, []);

  const filterClient = listClients.filter(val => val.Email === email);

  const myAM = listClients.length > 0 ? listClients[0]['Account Manager'] : '';

  const messageAdmin = async (item) => {
    console.log('item', item);
    console.log('myAM', myAM);
    // { Region: 'Highlands', Cask Type: 'Barel', Age: 5, Price: '5-10' }
    const [res, err] = await mApi.sendMessage({
      subject: 'Request cask info',
      from: email,
      message: `${name} (${email}) has requested info about this cask: Region ${item.Region} | type ${item['Cask Type']} | Age ${item.Age} | Price ${item.Price}`,
      am: myAM,
    });
    alert('Thank you, your request has been sent');
  }

  const genLink = async (id, type) => {
    const [res, err] = await cApi.genAssetsLink({ id, type });
    if (res && res.data) {
      // safari does not allow window.open in async functions
      // use setTimeout to get around this - it uses the main thread
      setTimeout(() => {
        window.open(res.data, '_blank');
      });
    }
  }

  const renderTab = () => {
    switch (tab) {
      case '1':
        return (
          <Col>
            {!!filterClient && filterClient.length === 0 ? (
              <Col>
                <Text>No record found</Text>
              </Col>
            ) : (
              <Table
                columns={[
                  'Account Manager', 'Portfolio', 'Purchased Date', 'Tier', 'Backend %', 'Spirit', 'Distillery', 'Cask Type', 'Cask No', 'Region',
                  'AYS', 'ABV', 'OLA/RLA', 'Latest Regauge', 'Currently Stored', 'Unit Price', 'Recommended Re-gauge Date',
                ].map(val => ({
                  key: val, title: val, padding: 5
                })).map(val => ({ ...val, center: true, width: 180, height: 40, padding: 10 }))}
                data={filterClient.map(val => ({
                  ...val,
                  ['AYS']: TimeHelper.format(val['AYS']),
                  ['Unit Price']: VarHelper.money(val['Unit Price']),
                  ['Portfolio']: VarHelper.money(val['Portfolio']),
                  ['Purchased Date']: TimeHelper.format(val['Purchased Date']),
                  // ['KYC']: () => (
                  //   <a href={val['KYC Link']}>{val['KYC']}</a>
                  // ),
                  'Recommended Re-gauge Date': TimeHelper.format(val['Recommended Re-gauge Date']),
                }))}
                breakpointToScroll="all"
                style={{ margin: 20 }}
              />
            )}
          </Col>
        )
      case '2':
        return !listAvailableCasks || listAvailableCasks.length == 0 ? null : (
          <Col>
            <TableWithAction
              titleBlackBg
              columns={[
                { key: 'Distillery', title: 'DISTILLERY', flex: 1, center: true },
                { key: 'Region', title: 'REGION', flex: 1, center: true },
                { key: 'Cask Type', title: 'CASK TYPE', flex: 1, center: true },
                { key: 'Cask number', title: 'CASK#', flex: 1, center: true },
                { key: 'Age', title: 'AGE', flex: 1, center: true },
                { key: 'Price', title: 'CASK PRICE', flex: 1, center: true },
              ]}
              data={listAvailableCasks}
              onPressAction={(item, i) => messageAdmin(item)}
              breakpointToScroll="sm"
              btnTitle="Request Info"
            />
          </Col>
        )
      case '4':
        return (
          <>
            {!listDocs || listDocs.length == 0 ? null : (
              <Col>
                <Text bold fontSize20 marginLeft30>Documents</Text>
                <Table
                  columns={[
                    { key: 'document', title: 'DOCUMENT', flex: 2, center: true },
                    { key: 'date', title: 'DATE', flex: 1, center: true },
                    { key: 'download', title: 'DOWNLOAD', flex: 1, center: true },
                  ]}
                  data={listDocs.filter(val => val['Client Email'] === email).map((val) => ({
                    document: val.Name || VarHelper.genNameFromPath(val['S3 Path']),
                    date: moment(val.created_at).format('DD/MM/YYYY'),
                    download: () => !val['S3 Path'] ? null : (
                      <a onClick={() => genLink(val.id, 'doc')} style={{ cursor: 'pointer' }}>Download</a>
                    ),
                  }))}
                  breakpointToScroll="sm"
                  style={{ margin: 20 }}
                />
              </Col>
            )}
            {!listCerts || listCerts.length == 0 ? null : (
              <Col>
                <Text bold fontSize20 marginLeft30>Certificates</Text>
                <Table
                  columns={[
                    { key: 'certificate', title: 'CERTIFICATE', flex: 2, center: true },
                    { key: 'date', title: 'DATE', flex: 1, center: true },
                    { key: 'download', title: 'DOWNLOAD', flex: 1, center: true },
                  ]}
                  data={listCerts.filter(val => val['Client Email'] === email).map((val) => ({
                    certificate: val.Name || VarHelper.genNameFromPath(val['S3 Path']),
                    date: moment(val.created_at).format('DD/MM/YYYY'),
                    download: () => !val['S3 Path'] ? null : (
                      <a onClick={() => genLink(val.id, 'cert')} style={{ cursor: 'pointer' }}>Download</a>
                    ),
                  }))}
                  breakpointToScroll="sm"
                  style={{ margin: 20 }}
                />
              </Col>
            )}

          </>
        )
      case '5':
        return !listPortfolioStrategy || listPortfolioStrategy.length == 0 ? null : (
          <Col>
            {/* <Table
              columns={Object.keys(listPortfolioStrategy[0]).filter(val => !['created_at', 'updated_at', 'id', 'Client Email'].includes(val)).map(val => ({
                key: val, title: val, padding: 5
              })).map(val => ({ ...val, center: true, width: 180, height: 40, padding: 10 }))}
              data={listPortfolioStrategy.filter(val => val['Client Email'] === email)}
              breakpointToScroll="sm"
              style={{ margin: 20 }}
            /> */}
          </Col>
        )
      case '6':
        return invoices !== null ? (
          <Table
            columns={[
              { key: 'number', title: 'Invoice Number', width: 150, center: true, padding: 20 },
              { key: 'date', title: 'Date', width: 150, center: true, padding: 20 },
              { key: 'due', title: 'Due Date', width: 150, center: true, padding: 20 },
              { key: 'total', title: 'Total', width: 150, center: true, padding: 20 },
              { key: 'space', title: '', flex: 1, noLeftBorder: true },
              { key: 'status', title: 'Status', width: 100, center: true, noLeftBorder: true, padding: 20 },
            ]}
            data={invoices.map(invoice => ({
              number: invoice.number,
              total: `${invoice.amount} ${invoice.currencyCode}`,
              date: moment(invoice.date).format('DD MMM, YYYY'),
              due: moment(invoice.dueDate).format('DD MMM, YYYY'),
              status: invoice.status === "PAID" ? "Paid" : "Not Paid"
            }))}
            style={{ margin: 20 }}
          />
        ) : <Text center margin30>No Invoices Found</Text>

      default:
        return null;
    }
  }

  const renderContainer = ({ children }) => {
    return (
      <Col>
        <Scroll height={height}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col middle height={bannerHeight}>
          <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Portfolio Details</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Col style={{ minHeight: height - 300 }} marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })} paddingHorizontal={rV({ xs: 4, lg: 20, xxxl: 40 })}>
          <Breadcrumbs
            title="Portfolio Details"
            style={{
              marginLeft: rV({ xs: 4, lg: 20, xxxl: 40 }),
              marginBottom: 20,
            }}
          />
          <Tabs
            tabs={listTabs}
            selected={tab}
            marginBottom={fSize(rV, 30)}
            onChange={(t) => {
              selectTab(t);
              const findTab = listTabs.find(val => val.id == t);
              Store.Firebase.logEvent('View Pursechase Tab', {
                tab: findTab ? findTab.title : ''
              });
            }}
          />
          {renderTab()}
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

PortfolioDetails.routeInfo = {
  title: 'Portfolio Details',
  path: '/portfolio-details',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default PortfolioDetails;